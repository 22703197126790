<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">Already have an account?</span
      >&nbsp;&nbsp;
      <router-link
        class="kt-link kt-login__signup-link"
        :to="{ name: 'login' }"
      >
        Login!
      </router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Sign Up</h3>
        </div>

        <!--begin::Form-->
        <!-- form -->
        <b-form @submit.stop.prevent="onSubmit">
          <!-- Personal Information -->
          <div class="kt-divider">
            <span></span>
            <span>Personal Information</span>
            <span></span>
          </div>

          <b-row>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  placeholder="First Name"
                  v-model.trim="$v.form.firstname.$model"
                  :state="validateState('firstname')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.firstname.$error">
                  Firstname is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  placeholder="Last Name"
                  v-model.trim="$v.form.lastname.$model"
                  :state="validateState('lastname')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.lastname.$error">
                  Lastname is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  placeholder="Your Email"
                  v-model.trim="$v.form.email.$model"
                  :state="validateState('email')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.email.$error">
                  Email is required and a valid email address.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  placeholder="Phone Number"
                  v-model.trim="$v.form.phonenumber.$model"
                  :state="validateState('phonenumber')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.phonenumber.$error">
                  Phonenumber is required and just a number.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Billing Address -->
          <div class="kt-divider">
            <span></span>
            <span>Billing Address</span>
            <span></span>
          </div>
          <b-form-group>
            <b-form-input
              placeholder="Company Name (Optional)"
              v-model.trim="$v.form.companyname.$model"
              :state="validateState('companyname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.companyname.$error">
              Company name at least 5 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-input
              placeholder="Street Address"
              v-model.trim="$v.form.address1.$model"
              :state="validateState('address1')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.address1.$error">
              Address is required and at least 5 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-input
              placeholder="Street Address 2"
              v-model.trim="$v.form.address2.$model"
              :state="validateState('address2')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.address2.$error">
              Address at least 5 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col sm="4">
              <b-form-group>
                <b-form-input
                  placeholder="City"
                  v-model.trim="$v.form.city.$model"
                  :state="validateState('city')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.city.$error">
                  City is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <b-form-input
                  placeholder="State"
                  v-model.trim="$v.form.state.$model"
                  :state="validateState('state')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.state.$error">
                  State is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <b-form-input
                  placeholder="Postcode"
                  v-model.trim="$v.form.postcode.$model"
                  :state="validateState('postcode')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.postcode.$error">
                  Postcode is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group>
                <b-form-select
                  v-model="form.country"
                  :options="country"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  placeholder="Tax ID (Optional)"
                  v-model.trim="$v.form.tax_id.$model"
                  :state="validateState('tax_id')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.tax_id.$error">
                  Tax ID at least 5 characters.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Additional Required Information -->
          <div class="kt-divider">
            <span></span>
            <span>Additional Required Information</span>
            <span></span>
          </div>

          <b-row>
            <b-col sm="6">
              <b-form-group label="Nomor HP">
                <b-form-input
                  v-model.trim="$v.form.no_hp.$model"
                  :state="validateState('no_hp')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.no_hp.$error">
                  Nomor HP at least 5 characters and just a number.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Nomor FAX">
                <b-form-input
                  v-model.trim="$v.form.fax.$model"
                  :state="validateState('fax')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.form.fax.$error">
                  Fax number at least 5.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Akun ini adalah">
                <b-form-select
                  v-model="form.account"
                  :options="account"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Mengetahui Rumahweb dari">
                <b-form-select
                  v-model="form.mengetahui"
                  :options="rumahweb"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-checkbox
            v-model="form.newsletter"
            value="on"
            unchecked-value="1"
          >
            Berlangganan Newsletter GRATIS
          </b-form-checkbox>

          <!-- Account Security -->
          <div class="kt-divider">
            <span></span>
            <span>Account Security</span>
            <span></span>
          </div>

          <b-row>
            <b-col sm="6">
              <b-form-group label="Password">
                <b-form-input
                  type="password"
                  v-model.trim="$v.form.password.$model"
                  :state="validateState('password')"
                ></b-form-input>
                <password v-model="form.password" :strength-meter-only="true" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Confirm Password">
                <b-form-input
                  type="password"
                  v-model.trim="$v.form.passwordconfirm.$model"
                  :state="validateState('passwordconfirm')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="kt-portlet agree">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--danger'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M11.167 4.5L2.825 19.513A1 1 0 003.7 21h17.517a1 1 0 00.853-1.521L12.894 4.464a1 1 0 00-1.727.035z" fill="#000" opacity=".3"/><rect fill="#000" x="11" y="9" width="2" height="7" rx="1"/><rect fill="#000" x="11" y="17" width="2" height="2" rx="1"/></g></svg>
                  Terms of Service
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <b-form-checkbox
                v-model="form.agreement"
                :value="true"
                :unchecked-value="false"
              >
                I have read and agree to the
                <a
                  href="http://www.rumahweb.com/tentang/service-level-agreement-sla"
                  target="_blank"
                  >Terms of Service</a
                >
              </b-form-checkbox>
            </div>
          </div>

          <div class="text-center">
            <button class="btn btn-primary" id="kt_submit">Register</button>
          </div>
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Vue from "vue";
import negara from "@/assets/rw/country.json";
import Password from "vue-password-strength-meter";
import ApiService from "@/common/api.service";
import { mapState } from "vuex";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { validationMixin } from "vuelidate";
import { ApiURL, notif } from "@/common/mixins/general.js";
import {
  email,
  required,
  minLength,
  maxLength,
  decimal,
  sameAs
} from "vuelidate/lib/validators";
import { reCaptcha } from "@/common/config.js";

Vue.use(VueReCaptcha, { siteKey: reCaptcha.v3.site_key });

export default {
  mixins: [validationMixin, ApiURL, notif],
  name: "register",
  components: { Password },
  data() {
    return {
      country: negara.option,
      account: [
        { text: "account pribadi", value: "account pribadi" },
        {
          text: "account perusahaan dan saya pemiliknya",
          value: "account perusahaan dan saya pemiliknya"
        },
        {
          text: "account perusahaan dan saya karyawan",
          value: "account perusahaan dan saya karyawan"
        },
        { text: "account pelanggan saya", value: "account pelanggan saya" },
        { text: "account rekan saya", value: "account rekan saya" }
      ],
      rumahweb: [
        { value: "search engine", text: "search engine" },
        { value: "majalah", text: "majalah" },
        { value: "website lain", text: "website lain" },
        { value: "referensi teman", text: "referensi teman" },
        { value: "lain - lain", text: "lain - lain" }
      ],
      form: {
        account: "account pribadi",
        mengetahui: "search engine",
        firstname: "",
        lastname: "",
        companyname: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postcode: "",
        country: "ID",
        phonenumber: "",
        tax_id: "",
        password: "",
        passwordconfirm: "",
        newsletter: "1",
        no_hp: "",
        fax: "",
        agreement: false
      }
    };
  },
  validations: {
    form: {
      email: { required, email },
      firstname: { required },
      lastname: { required },
      phonenumber: {
        required,
        decimal,
        minLength: minLength(1),
        maxLength: maxLength(13)
      },
      tax_id: { minLength: minLength(5) },
      no_hp: {
        decimal,
        minLength: minLength(5),
        maxLength: maxLength(13)
      },
      address1: { required, minLength: minLength(5) },
      address2: { minLength: minLength(5) },
      city: { required },
      postcode: { decimal, required },
      state: { required },
      country: { required },
      companyname: { minLength: minLength(5) },
      password: { required, minLength: minLength(3) },
      passwordconfirm: { required, sameAsPassword: sameAs("password") },
      fax: { minLength: minLength(5) }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (!this.form.agreement) {
        this.mx_swal("You must agree to the terms of service");
      } else {
        // send recaptcha
        this.$recaptcha("login").then(token => {
          const submitButton = document.getElementById("kt_submit");
          submitButton.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right",
            "disabled"
          );

          let new_client = this.form;
          new_client.reCaptcha = token;
          ApiService.post(this.api.auth.register, new_client)
            .then(({ data }) => {
              this.mx_swal(data.message, "", "success");
              this.$router.push({ path: "/login" });
            })
            .catch(({ response }) => {
              this.mx_swal(response.data.message);
            })
            .finally(() => {
              submitButton.classList.remove(
                "kt-spinner",
                "kt-spinner--light",
                "kt-spinner--right",
                "disabled"
              );
            });
        });
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  }
};
</script>

<style scoped>
.kt-login.kt-login--v1
  .kt-login__wrapper
  .kt-login__body
  .kt-login__form
  .kt-login__title {
  margin-bottom: 0;
}
.kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form {
  max-width: 100%;
}
.kt-divider {
  padding: 3% 0 3% 0;
}
.kt-divider span {
  font-size: 1.5em;
}
.kt-divider span.or {
  font-size: 1rem;
}
.kt-portlet.agree {
  border: 2px solid #f2dede;
}
.kt-portlet.agree > .kt-portlet__head {
  background-color: #f2dede;
}
</style>
